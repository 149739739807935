import { AppEnvironment, IEnvironment } from '@hemro/lib/config';
import { LogTypes } from '@yukawa/chain-base-angular-client';


export const environment: IEnvironment = {
    app         : AppEnvironment.admin,
    buildNumber: '698',
    buildVersion: '1.0.7',
    production  : false,
    logTypes    : [
        LogTypes.error,
        LogTypes.warn,
    ],
};
